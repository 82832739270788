const initialState = {
  selectedNetworks: [],
  currentPlacements: {},
  openAdSlug: null,
};

const SELECT_NETWORK = "adNetworks/selectNetwork";
const DESELECT_NETWORK = "adNetworks/deselectNetwork";
const SET_OPEN_AD_SLUG = "adNetworks/setOpenAdSlug";
const CLEAR_OPEN_AD_SLUG = "adNetworks/clearOpenAdSlug";
const ADD_PLACEMENT = "adNetworks/addPlacement";
const SET_PLACEMENTS = "adNetworks/setPlacements";

export const selectNetwork = (network) => ({
  type: SELECT_NETWORK,
  payload: network,
});

export const deselectNetwork = (network) => ({
  type: DESELECT_NETWORK,
  payload: network,
});

export const addPlacement = (slug, newPlacement) => ({
  type: ADD_PLACEMENT,
  payload: { slug, newPlacement },
});

export const setPlacements = (placements) => ({
  type: SET_PLACEMENTS,
  payload: placements,
});

export const setOpenAdSlug = (slug) => ({
  type: SET_OPEN_AD_SLUG,
  payload: slug,
});

export const clearOpenAdSlug = () => ({
  type: CLEAR_OPEN_AD_SLUG,
});

export const adNetworksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_NETWORK:
      return {
        ...state,
        selectedNetworks: [...state.selectedNetworks, action.payload],
      };
    case DESELECT_NETWORK:
      return {
        ...state,
        selectedNetworks: state.selectedNetworks.filter(
          (net) => net.NetworkId !== action.payload.NetworkId
        ),
      };
    case SET_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: action.payload,
      };
    case CLEAR_OPEN_AD_SLUG:
      return {
        ...state,
        openAdSlug: null,
      };
    case ADD_PLACEMENT: {
      const { slug, newPlacement } = action.payload;

      const existingPlacements =
        state.currentPlacements[slug]?.placements || [];

      return {
        ...state,
        currentPlacements: {
          ...state.currentPlacements,
          [slug]: {
            ...state.currentPlacements[slug],
            placements: [...existingPlacements, newPlacement],
          },
        },
      };
    }
    case SET_PLACEMENTS:
      return {
        ...state,
        currentPlacements: action.payload,
      };
    default:
      return state;
  }
};
