export const loadState = (key) => {
  if (typeof window === "undefined" || !window.localStorage) {
    return undefined;
  }

  try {
    const serializedState = localStorage.getItem(key);
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    console.error("Error loading state from localStorage:", err);
    return undefined;
  }
};

export const saveState = (key, state) => {
  if (typeof window === "undefined" || !window.localStorage) {
    return;
  }

  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.error("Error saving state to localStorage:", err);
  }
};
