import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { adNetworksReducer } from "./adNetworksSlice";
import booleanReducer from "./booleanSlice";
import { loadState, saveState } from "./utils/localstorage";

const persistedAdNetworksState = loadState("selectedNetworks");

const rootReducer = combineReducers({
  adNetworks: adNetworksReducer,
  boolean: booleanReducer,
});

const preloadedState = {
  adNetworks: {
    ...adNetworksReducer(undefined, { type: "@@INIT" }),
    selectedNetworks: persistedAdNetworksState || [],
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(() => {
  const state = store.getState();
  saveState("selectedNetworks", state.adNetworks.selectedNetworks);
});

export default store;
